import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

function TermsOfService() {
    return (
        <Layout>
            <Seo title="Terms Of Service" />
            <section className="hero bg_img" data-background="/bg/hero.jpg">
                <div className="hero__shape">
                    <img src="/elements/hero/shape-2.png" alt="no surrender" />
                </div>
                <div className="el-1">
                    <img src="/elements/hero/el-1.png" alt="no surrender" />
                </div>
                <div className="el-2">
                    <img src="/elements/hero/el-2.png" alt="no surrender" />
                </div>
                <div className="el-3">
                    <img src="/elements/hero/el-3.png" alt="no surrender" />
                </div>
                <div className="el-4">
                    <img src="/elements/hero/el-4.png" alt="no surrender" />
                </div>
                <div className="el-5">
                    <img src="/elements/hero/el-5.png" alt="no surrender" />
                </div>
                <div className="el-6">
                    <img src="/elements/hero/el-6.png" alt="no surrender" />
                </div>
                <div className="el-7">
                    <img src="/elements/hero/el-7.png" alt="no surrender" />
                </div>
                <div className="el-8">
                    <img src="/elements/hero/el-8.png" alt="no surrender" />
                </div>
                <div className="el-9">
                    <img src="/elements/hero/el-9.png" alt="no surrender" />
                </div>
                <div className="el-10">
                    <img src="/elements/hero/el-10.png" alt="no surrender" />
                </div>
                <div className="el-11">
                    <img src="/elements/hero/el-11.png" alt="no surrender" />
                </div>
            </section>
            <section className="pb-120">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="hero__content d-flex flex-column align-items-center">
                                <h2
                                    className="hero__title wow fadeInUp text-center"
                                    data-wow-duration="0.5s"
                                    data-wow-delay="0.5s"
                                >
                                    TERMS OF SERVICE
                                </h2>
                                <div>
                                    <p class="c3"><span class="c0">Effective Date: April 15, 2021</span></p>
                                    <p class="c3"><span class="c19">The terms of this agreement (&quot;Terms of Service&quot;) govern the relationship
                                        between you and </span><span class="c19">NoSurrender, a TURKISH company (business ID 6321122630)</span><span
                                            class="c19">&nbsp;having its registered office at Sahkulu mh. Yazarlar sk No:27/5 Galata &#304;stanbul
                                            Turkey &nbsp;(hereinafter &quot;NoSurrender&quot; or &quot;Us&quot; or &quot;We&quot;) regarding your use of
                                            NoSurrender&#39;s games, store, sites and related services (the &quot;Service&quot;). Use of the Service is
                                            also governed by NoSurrender&#39;s </span><span class="c19"><a class="c1"
                                                href="https://nosurrender.studio/privacy-policy">Privacy
                                                Policy</a></span><span class="c0">&nbsp;and other relevant policies, which are incorporated herein by
                                                    reference. </span></p>
                                    <p class="c3"><span class="c19">Before accessing or using the Service, including browsing any NoSurrender website or
                                        accessing a game, you must agree to these Terms of Service and the </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">. A guest account may be created for you for the use of the Service
                                                and you may also be required to register an account on the Service (collectively &quot;Account&quot;). These
                                                Accounts include, for example, game Accounts and NoSurrender ID. By using or registering for an Account or
                                                otherwise using the Service, you affirm that you are the legal age of majority in your country of residence.
                                                If you are not, your legal guardian must review and agree to these Terms of Service.</span></p>
                                    <p class="c3"><span class="c0">BY INSTALLING, USING OR OTHERWISE ACCESSING THE SERVICE, YOU AGREE TO THESE TERMS OF
                                        SERVICE. IF YOU DO NOT AGREE TO THESE TERMS OF SERVICE, PLEASE DO NOT INSTALL, USE OR OTHERWISE ACCESS THE
                                        SERVICE. USE OF THE SERVICE IS VOID WHERE PROHIBITED.</span></p>
                                    <p class="c3"><span class="c0">IMPORTANT NOTICE: For U.S. and Canadian residents, you also agree that disputes with
                                        NoSurrender must be resolved on an individual basis through final and binding arbitration as described in
                                        Section 8 (&ldquo;Dispute Resolution&rdquo;).</span></p>
                                    <p class="c3"><span class="c19">NoSurrender reserves the right, at its discretion, to change, modify, add or remove
                                        portions of these Terms of Service, its </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c19">&nbsp;and other relevant NoSurrender policies at any time by posting
                                                the amended terms on the Service. You will be deemed to have accepted such changes by continuing to use the
                                                Service. If at any point you do not agree to any portion of the then-current version of our Terms of
                                                Service, the NoSurrender </span><span class="c19"><a class="c1"
                                                    href="https://nosurrender.studio/privacy-policy">Privacy
                                                    Policy</a></span><span class="c0">, or any other NoSurrender policy, rules or codes of conduct relating
                                                        to your use of the Service, your right to use the Service shall immediately terminate, and you must
                                                        immediately stop using the Service.</span></p>
                                    <p class="c3"><span class="c0">1. Using the Service</span></p>
                                    <p class="c3"><span class="c0">1.1. Your Right to Use the Service</span></p>
                                    <p class="c3"><span class="c0">Subject to your agreement and continuing compliance with these Terms of Service and
                                        any other relevant NoSurrender policies, you have a non-exclusive, non-transferable, non-sublicensable,
                                        revocable and limited right to access and use the Service for your own non-commercial entertainment
                                        purposes. You agree not to use the Service for any other purpose.</span></p>
                                    <p class="c3"><span class="c0">The following restrictions apply to the use of the Service:</span></p>
                                    <ul class="c11 lst-kix_v5d8p96p3vdz-0 start">
                                        <li class="c12 c15 li-bullet-0"><span class="c0">You accept full responsibility for any unauthorized use of the
                                            Service by minors. You are responsible for any use of your credit card or other payment instrument (such
                                            as Paypal) by minors.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">You shall not (or attempt to) purchase, sell, rent or give away
                                            your Account, create an Account using a false identity or information, or on behalf of someone other
                                            than yourself; You shall not use the Service if you have previously been removed by NoSurrender, or
                                            previously been banned from playing any NoSurrender game.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">You shall not use the Service to advertise, or solicit, or
                                            transmit any commercial advertisements, including chain letters, junk or spam e-mail or repetitive or
                                            misleading messages to anyone.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">Login information and Your Account</span></p>
                                    <p class="c3"><span class="c0">You may be required to select a password for your Account or you may also use other
                                        credentials to access the Account (&quot;Login Information&quot;). You shall not share the Account or the
                                        Login Information, nor let anyone else access your Account or do anything else that might jeopardize the
                                        security of your Account. In the event you become aware of or reasonably suspect any breach of security,
                                        including without limitation any loss, theft, or unauthorized disclosure of the Login Information, you must
                                        immediately notify NoSurrender and modify your Login Information. You are solely responsible for maintaining
                                        the confidentiality of the Login Information, and you will be responsible for all uses of the Login
                                        Information, including purchases, whether or not authorized by you. You are responsible for anything that
                                        happens through your Account.</span></p>
                                    <p class="c3"><span class="c0">NoSurrender reserves the right to remove or reclaim any usernames at any time and for
                                        any reason, including but not limited to claims by a third party that a username violates the third
                                        party&#39;s rights.</span></p>
                                    <p class="c3"><span class="c0">Unless you are using NoSurrender ID, the Service supports only one Account per game
                                        on a supported device. </span></p>
                                    <p class="c3"><span class="c0">Use Limitations</span></p>
                                    <p class="c3"><span class="c0">Any use of the Service in violation of these Use Limitations is strictly prohibited,
                                        can result in the immediate revocation of your limited right and may subject you to liability for violations
                                        of law.</span></p>
                                    <p class="c3"><span class="c0">You agree that you will not, under any circumstances:</span></p>
                                    <ul class="c11 lst-kix_wvoj6egwvkis-0 start">
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Engage in any act that NoSurrender deems to be in conflict with
                                            the spirit or intent of the Service or make improper use of NoSurrender&#39;s support services.</span>
                                        </li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Use or take part (directly or indirectly) in the use of cheats,
                                            exploits, automation software, emulators, bots, hacks, mods or any unauthorized third-party software
                                            designed to modify or interfere with the Service, any NoSurrender game or any NoSurrender game
                                            experience.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Modify or cause to be modified any files that are a part of the
                                            Service or any NoSurrender game without NoSurrender&#39;s express written consent.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Disrupt, interfere with or otherwise adversely affect the
                                            normal flow of the Service or otherwise act in a manner that may negatively affect other users&#39;
                                            experience when using the Service or playing NoSurrender&#39;s games. This includes win trading and any
                                            other kind of manipulation of rankings, taking advantage of errors in the Service to gain an unfair edge
                                            over other players and any other act that intentionally abuses or goes against the design of the
                                            Service.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Disrupt, overburden, or aid or assist in the disruption or
                                            overburdening of any computer or server used to offer or support the Service or any NoSurrender game
                                            environment.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Institute, assist, or become involved in any type of attack,
                                            including without limitation distribution of a virus, denial of service attacks upon the Service, or
                                            other attempts to disrupt the Service or any other person&#39;s use or enjoyment of the Service.</span>
                                        </li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Attempt to gain unauthorized access to the Service, Accounts
                                            registered or used by others or to the computers, servers, or networks connected to the Service by any
                                            means other than the user interface provided by NoSurrender, including but not limited to, by
                                            circumventing or modifying, attempting to circumvent or modify, or encouraging or assisting any other
                                            person to circumvent or modify, any security, technology, device, or software that is part of the
                                            Service.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Post any information that is abusive, threatening, obscene,
                                            defamatory, libelous, or racially, sexually, religiously, or otherwise objectionable or offensive or
                                            engage in ongoing toxic behavior, such as by repeatedly posting information on an unsolicited
                                            basis.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Post any information that contains nudity, excessive violence,
                                            or offensive subject matter or that contains a link to such content.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Attempt to, or harass, abuse, or harm, or advocate or incite
                                            harassment, abuse, or harm of another person, group, including NoSurrender employees, including
                                            NoSurrender&#39;s customer service representatives.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Make available through the Service any material or information
                                            that infringes any copyright, trademark, patent, trade secret, right of privacy, right of publicity, or
                                            other right of any person or entity or impersonates any other person, including without limitation a
                                            NoSurrender employee.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Reverse engineer, decompile, disassemble, decipher or otherwise
                                            attempt to derive the source code for any underlying software or other intellectual property used to
                                            provide the Service or any NoSurrender game, or to obtain any information from the Service or any
                                            NoSurrender game using any method not expressly permitted by NoSurrender.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Solicit or attempt to solicit Login Information or any other
                                            login credentials or personal information from other users of the Service or any NoSurrender
                                            game.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Collect or post anyone&#39;s private information, including
                                            personally identifiable information (whether in text, image or video form), identification documents, or
                                            financial information through the Service.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Use any NoSurrender game for gambling, betting or any similar
                                            activity in which prizes or rewards can be won (directly or indirectly), including betting on the
                                            outcome of matches in which you participate as a player, irrespective of whether or not there is a fee
                                            or stake involved.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">NoSurrender reserves the right to determine what conduct it considers to be in
                                        violation of the rules of use or otherwise outside the intent or spirit of these Terms of Service or the
                                        Service itself. NoSurrender reserves the right to take action as a result, which may include terminating
                                        your Account and prohibiting you from using the Service in whole or in part.</span></p>
                                    <p class="c3"><span class="c0">1.2. Suspension and Termination of Account and Service</span></p>
                                    <p class="c3"><span class="c0">WITHOUT LIMITING ANY OTHER REMEDIES, NoSurrender MAY LIMIT, SUSPEND, TERMINATE,
                                        MODIFY, OR DELETE ACCOUNTS OR ACCESS TO THE SERVICE OR PORTIONS THEREOF WITH OR WITHOUT NOTICE TO YOU (i) IF
                                        YOU ARE, OR NoSurrender SUSPECTS THAT YOU ARE, FAILING TO COMPLY WITH THESE TERMS OF SERVICE; OR (ii) FOR
                                        ANY ACTUAL OR SUSPECTED ILLEGAL OR IMPROPER USE OF THE SERVICE. YOU CAN LOSE YOUR USER NAME AND PERSONA IN
                                        THE SERVICE AS A RESULT OF ACCOUNT TERMINATION OR LIMITATION, AS WELL AS ANY BENEFITS, PRIVILEGES, EARNED
                                        VIRTUAL ITEMS AND PURCHASED VIRTUAL ITEMS ASSOCIATED WITH YOUR USE OF THE SERVICE, AND NoSurrender IS UNDER
                                        NO OBLIGATION TO COMPENSATE YOU FOR ANY SUCH LOSSES OR RESULTS.</span></p>
                                    <p class="c3"><span class="c0">WITHOUT LIMITING OUR OTHER REMEDIES, WE MAY LIMIT, SUSPEND OR TERMINATE THE SERVICE
                                        AND ACCOUNTS OR PORTIONS THEREOF, PROHIBIT ACCESS TO OUR GAMES AND SITES, AND THEIR CONTENT, SERVICES AND
                                        TOOLS, DELAY OR REMOVE HOSTED CONTENT, AND TAKE TECHNICAL AND LEGAL STEPS TO PREVENT USERS FROM ACCESSING
                                        THE SERVICE IF WE BELIEVE THAT THEY ARE CREATING RISK OR POSSIBLE LEGAL LIABILITIES, INFRINGING THE
                                        INTELLECTUAL PROPERTY RIGHTS OF THIRD PARTIES, OR ACTING INCONSISTENTLY WITH THE LETTER OR SPIRIT OF OUR
                                        TERMS OR &nbsp;POLICIES. ADDITIONALLY, WE MAY, IN APPROPRIATE CIRCUMSTANCES AND AT OUR SOLE DISCRETION,
                                        SUSPEND OR TERMINATE ACCOUNTS OF USERS WHO MAY BE REPEAT INFRINGERS OF THIRD-PARTY INTELLECTUAL PROPERTY
                                        RIGHTS.</span></p>
                                    <p class="c3"><span class="c0">NoSurrender RESERVES THE RIGHT TO TERMINATE ANY ACCOUNT THAT HAS BEEN INACTIVE FOR 90
                                        DAYS.</span></p>
                                    <p class="c3"><span class="c0">NoSurrender reserves the right to stop offering and/or supporting the Service or a
                                        particular game or part of the Service at any time, at which point your right to use the Service or a part
                                        thereof will be automatically terminated. In such event, NoSurrender shall not be required to provide
                                        refunds, benefits or other compensation to users in connection with such discontinued Service. Termination
                                        of your Account can include disabling your access to the Service or any part thereof including any content
                                        you submitted or others submitted.</span></p>
                                    <p class="c3"><span class="c19">You may terminate your Account at any time and for any reason by following the
                                        process described on our support page located at </span><span class="c19"><a class="c1"
                                            href="https://support.nosurrender.studio/">https://support.nosurrender.studio/</a></span><span
                                                class="c0">&nbsp;informing NoSurrender that you wish to terminate your Account.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">2. Ownership</span></p>
                                    <p class="c3"><span class="c0">2.1. Games and Service</span></p>
                                    <p class="c3"><span class="c0">All rights, title and interest in and to the Service (including without limitation
                                        any games, titles, computer code, themes, objects, characters, character names, stories, dialogue, catch
                                        phrases, concepts, artwork, animations, sounds, musical compositions, audio-visual effects, methods of
                                        operation, moral rights, documentation, in-game chat transcripts, character profile information, recordings
                                        of games played using a NoSurrender game client, and the NoSurrender game clients and server software) are
                                        owned by NoSurrender. NoSurrender reserves all rights, including without limitation, all intellectual
                                        property rights or other proprietary rights, in connection with its games and the Service.</span></p>
                                    <p class="c3"><span class="c0">2.2. Accounts</span></p>
                                    <p class="c3"><span class="c0">NOTWITHSTANDING ANYTHING TO THE CONTRARY HEREIN, YOU ACKNOWLEDGE AND AGREE THAT YOU
                                        SHALL HAVE NO OWNERSHIP OR OTHER PROPERTY INTEREST IN THE ACCOUNT, AND YOU FURTHER ACKNOWLEDGE AND AGREE
                                        THAT ALL RIGHTS IN AND TO THE ACCOUNT ARE AND SHALL FOREVER BE OWNED BY AND INURE TO THE BENEFIT OF
                                        NoSurrender.</span></p>
                                    <p class="c3"><span class="c0">2.3. Virtual Content</span></p>
                                    <p class="c3"><span class="c0">NoSurrender owns, has licensed, or otherwise has rights to use all of the content
                                        that appears in the Service or in NoSurrender games. Notwithstanding any provision to the contrary herein,
                                        you agree that you have no right or title in or to any content that appears in the Service, including
                                        without limitation the virtual items, content, features, goods, services or currency appearing or
                                        originating in any NoSurrender game, whether earned in a game or purchased from NoSurrender, or any other
                                        attributes associated with an Account or stored on the Service.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">3. User Content</span></p>
                                    <p class="c3"><span class="c0">3.1. Submission of User Content</span></p>
                                    <p class="c3"><span class="c19">&quot;User Content&quot; means any communications, images, sounds, and all the
                                        material, data, and information that you upload or transmit through a NoSurrender game client or the
                                        Service, or that other users upload or transmit, including without limitation any chat text. By transmitting
                                        or submitting any User Content while using the Service, you affirm, represent and warrant that such
                                        transmission or submission is (a) accurate and not confidential or misleading; (b) not in violation of any
                                        laws, contractual restrictions or other third party rights, and that you have permission from any third
                                        party whose personal information or intellectual property is comprised in the User Content; (c) free of
                                        viruses, adware, spyware, worms or other malicious code; and (d) you acknowledge and agree that any of your
                                        personal information within such content will at all times be processed by NoSurrender in accordance with
                                        its </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">.</span></p>
                                    <p class="c3"><span class="c0">3.1.1. Content Screening</span></p>
                                    <p class="c3"><span class="c0">NoSurrender assumes no responsibility for the conduct of any user submitting any User
                                        Content, and assumes no responsibility for monitoring the Service for inappropriate content or conduct. We
                                        do not, and cannot, pre-screen or monitor all User Content. Your use of the Service is at your own risk. By
                                        using the Service, you may be exposed to User Content that is offensive, indecent or otherwise not in line
                                        with your expectations. You bear all risks associated with the use of any User Content available in
                                        connection with the Service. At our discretion, our representatives or technology may monitor and/or record
                                        your interaction with the Service or communications (including without limitation chat text) when you are
                                        using the Service.</span></p>
                                    <p class="c3"><span class="c0">By entering into these Terms of Service, you hereby provide your irrevocable consent
                                        to such monitoring and recording. You acknowledge and agree that you have no expectation of privacy
                                        concerning the transmission of any User Content, including without limitation chat text or voice
                                        communications.</span></p>
                                    <p class="c3"><span class="c0">NoSurrender reserves the right in its sole discretion to review, monitor, prohibit,
                                        edit, delete, disable access to or otherwise make unavailable any User Content (including without limitation
                                        your User Content) without notice for any reason or for no reason at any time. If at any time NoSurrender
                                        chooses, in its sole discretion, to monitor the Service, NoSurrender nonetheless assumes no responsibility
                                        for User Content and assumes no obligation to modify or remove any inappropriate User Content. We have the
                                        right, but not the obligation, in our sole discretion to edit, refuse to post, or remove any User
                                        Content.</span></p>
                                    <p class="c3"><span class="c0">3.2. Information Use by Other Members of the Service</span></p>
                                    <p class="c3"><span class="c0">3.2.1. Public Discourse</span></p>
                                    <p class="c3"><span class="c0">The Service may include various forums, blogs and chat features where you can post
                                        User Content, including your observations and comments on designated topics. NoSurrender cannot guarantee
                                        that other members will not use the ideas and information that you share. Therefore, if you have an idea or
                                        information that you would like to keep confidential and/or don&#39;t want others to use, do not post it on
                                        the Service. NoSurrender shall have no responsibility to evaluate, use or compensate you for any ideas or
                                        information you may choose to submit.</span></p>
                                    <p class="c3"><span class="c0">3.2.2. Responsible For Your Own Content</span></p>
                                    <p class="c3"><span class="c0">You are solely responsible for the information that you post on, through or in
                                        connection with the Service and that you provide to others. NoSurrender may reject, refuse to post or delete
                                        any User Content for any or no reason, including, but not limited to, User Content that in the sole judgment
                                        of NoSurrender violates these Terms of Service.</span></p>
                                    <p class="c3"><span class="c0">3.2.3. Your License to NoSurrender</span></p>
                                    <p class="c3"><span class="c0">You hereby grant to NoSurrender an irrevocable, perpetual, transferable, fully
                                        paid-up, royalty-free, worldwide license (including the right to sublicense and assign to third party) and
                                        right to copy, reproduce, fix, adapt, modify, create derivative works from, manufacture, commercialize,
                                        publish, distribute, sell, license, sublicense, transfer, lease, transmit, publicly display, publicly
                                        perform, or provide access to electronically, broadcast, communicate to the public by telecommunication,
                                        display, perform, enter into computer memory, and use and practice, in any way, your User Content as well as
                                        all modified and derivative works thereof in connection with our provision of the Service, including
                                        marketing and promotions of the Service. You also hereby grant to NoSurrender the right to authorize others
                                        to exercise any of the rights granted to NoSurrender under these Terms of Service. You further hereby grant
                                        to NoSurrender the unconditional, irrevocable right to use and exploit your name, likeness and any other
                                        information or material included in any User Content and in connection with any User Content, without any
                                        obligation to you. Except as prohibited by law, you waive any rights of attribution and/or any moral rights
                                        you may have in your User Content, regardless of whether your User Content is altered or changed in any
                                        manner. NoSurrender does not claim any ownership rights in your User Content and nothing in these Terms of
                                        Service is intended to restrict any rights that you may have to use and exploit your User Content.
                                        NoSurrender has no obligation to monitor or enforce your intellectual property rights in or to your User
                                        Content. </span></p>
                                    <p class="c3"><span class="c0">3.3. User Interactions</span></p>
                                    <p class="c3"><span class="c0">You are solely responsible for your interactions with other users of the Service and
                                        any other parties with whom you interact through the Service and/or NoSurrender games. NoSurrender reserves
                                        the right, but has no obligation, to become involved in any way with these disputes. You will fully
                                        cooperate with NoSurrender to investigate any suspected unlawful, fraudulent or improper activity,
                                        including, without limitation, granting NoSurrender access to any password-protected portions of your
                                        Account.</span></p>
                                    <p class="c3"><span class="c0">If you have a dispute with one or more users, you release us (and our officers,
                                        directors, agents, subsidiaries, joint ventures and employees) from claims, demands and damages (actual and
                                        consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such
                                        disputes.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">4. Fees and Purchase Terms</span></p>
                                    <p class="c3"><span class="c0">4.1.Purchases </span></p>
                                    <p class="c3"><span class="c0">In the Service you may purchase, with &quot;real world&quot; money, a limited,
                                        personal, non-transferable, non-sublicensable, revocable right to use (a) virtual currency, including but
                                        not limited to virtual cash or diamonds, all for use in NoSurrender games; (b) virtual in-game items,
                                        content or features; and (c) other goods or services (points a &ndash; c are jointly referred to as
                                        &quot;Virtual Items&quot;). You are only allowed to purchase Virtual Items from us or our authorised
                                        partners through the Service, and not in any other way.</span></p>
                                    <p class="c3"><span class="c0">NoSurrender may manage, regulate, control, modify or eliminate Virtual Items at any
                                        time, with or without notice. NoSurrender shall have no liability to you or any third party in the event
                                        that NoSurrender exercises any such rights.</span></p>
                                    <p class="c3"><span class="c0">The transfer of Virtual Items is prohibited except where expressly authorized in the
                                        Service. Other than as expressly authorized in the Service, you shall not sell, purchase, redeem or
                                        otherwise transfer Virtual Items to any person or entity or attempt any of the aforesaid, including but not
                                        limited to NoSurrender, another user or any third party.</span></p>
                                    <p class="c3"><span class="c0">ALL PURCHASES AND REDEMPTIONS OF VIRTUAL ITEMS MADE THROUGH THE SERVICE ARE FINAL AND
                                        NON-REFUNDABLE.</span></p>
                                    <p class="c3"><span class="c0">The provision of Virtual Items for use in NoSurrender games is a service provided by
                                        NoSurrender that commences immediately upon acceptance by NoSurrender of your purchase.</span></p>
                                    <p class="c3"><span class="c0">4.2. Payment of Fees</span></p>
                                    <p class="c3"><span class="c0">You agree to pay all fees and applicable taxes incurred by you or anyone using an
                                        Account registered to you. NoSurrender may revise the pricing for the Virtual Items offered through the
                                        Service at any time. YOU ACKNOWLEDGE THAT NoSurrender IS NOT REQUIRED TO PROVIDE A REFUND FOR ANY REASON,
                                        AND THAT YOU WILL NOT RECEIVE MONEY OR OTHER COMPENSATION FOR UNUSED VIRTUAL ITEMS WHEN AN ACCOUNT IS
                                        CLOSED, WHETHER SUCH CLOSURE WAS VOLUNTARY OR INVOLUNTARY.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">5. Updates to the Service</span></p>
                                    <p class="c3"><span class="c0">You understand that the Service is an evolving one. NoSurrender may require that you
                                        accept updates to the Service and to NoSurrender&#39;s games you have installed on your device or computer.
                                        You acknowledge and agree that NoSurrender may update the Service and NoSurrender games, with or without
                                        notifying you. You may need to update third party software from time to time in order to receive the Service
                                        and play NoSurrender games.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">6. Disclaimer of Warranties</span></p>
                                    <p class="c3"><span class="c0">WITHOUT LIMITING NoSurrender&#39;S LIABILITY UNDER SECTION 7 BELOW, THE SERVICE IS
                                        PROVIDED ON AN &quot;AS IS&quot; AND &quot;AS AVAILABLE&quot; BASIS FOR YOUR USE, WITHOUT WARRANTIES OF ANY
                                        KIND, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                                        PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND THOSE ARISING FROM COURSE OF DEALING OR USAGE OF TRADE.
                                        NoSurrender DOES NOT WARRANT THAT YOU WILL BE ABLE TO ACCESS OR USE THE SERVICE AT THE TIMES OR LOCATIONS OF
                                        YOUR CHOOSING; THAT THE SERVICE WILL BE UNINTERRUPTED OR ERROR-FREE; THAT DEFECTS WILL BE CORRECTED; OR THAT
                                        THE GAME OR THE SERVICE ARE FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.</span></p>
                                    <p class="c3"><span class="c0">Some jurisdictions do not allow the exclusion of certain warranties. Accordingly,
                                        some of the above disclaimers may not apply to you.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">7. Limitation of Liability; Sole and Exclusive Remedy; Indemnification</span></p>
                                    <p class="c3"><span class="c0">TO THE MAXIMUM EXTENT PERMITTED BY LAW, NoSurrender SHALL NOT BE LIABLE TO YOU FOR
                                        ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR OTHER SIMILAR DAMAGES, INCLUDING BUT NOT
                                        LIMITED TO LOSS OF REVENUES, LOST PROFITS, LOST DATA OR BUSINESS INTERRUPTION OR OTHER INTANGIBLE LOSSES
                                        (HOWEVER SUCH LOSSES ARE QUALIFIED), ARISING OUT OF OR RELATING IN ANY WAY TO THESE TERMS OF SERVICE OR THE
                                        SERVICE ITSELF, WHETHER BASED ON CONTRACT, TORT OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT NoSurrender
                                        HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. TO THE EXTENT NOT PROHIBITED BY LAW, NoSurrender SHALL
                                        NOT BE LIABLE TO YOU FOR MORE THAN THE AMOUNT YOU HAVE PAID TO NoSurrender IN ACCORDANCE WITH THESE TERMS OF
                                        SERVICE IN THE SIX (6) MONTHS IMMEDIATELY PRECEDING THE DATE ON WHICH YOU FIRST ASSERT A CLAIM. YOU
                                        ACKNOWLEDGE AND AGREE THAT IF YOU HAVE NOT PAID ANYTHING TO NoSurrender DURING SUCH TIME PERIOD, YOUR SOLE
                                        REMEDY (AND NoSurrender&#39;S EXCLUSIVE LIABILITY) FOR ANY DISPUTE WITH NoSurrender IS TO STOP USING THE
                                        SERVICE AND TO CANCEL YOUR ACCOUNT. </span></p>
                                    <p class="c3"><span class="c0">NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT THE STATUTORY RIGHTS OF ANY CONSUMER
                                        OR EXCLUDE OR RESTRICT ANY LIABILITY RESULTING FROM GROSS NEGLIGENCE OR WILLFUL MISCONDUCT OF NoSurrender OR
                                        FOR DEATH OR PERSONAL INJURY ARISING FROM ANY NEGLIGENCE OR FRAUD OF NoSurrender.</span></p>
                                    <p class="c3"><span class="c0">You agree to indemnify, defend and hold NoSurrender (and our officers, directors,
                                        agents, subsidiaries, joint ventures and employees) harmless from any claim, demand, damages or other
                                        losses, including reasonable attorneys&#39; fees, asserted by any third-party resulting from or arising out
                                        of your use of the Service, or any breach by you of these Terms of Service, however the foregoing does not
                                        apply if the infringement of rights is not attributable to your intentional or negligent behavior.</span>
                                    </p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">8. Dispute Resolution</span></p>
                                    <p class="c3"><span class="c19">You and NoSurrender agree that the processes for dispute resolution described in
                                        this agreement will apply to any dispute or claims related to these Terms of Service, the </span><span
                                            class="c19"><a class="c1"
                                                href="https://nosurrender.studio/privacy-policy">Privacy
                                                Policy</a></span><span class="c0">, or the Service. Disputes include any claims of any kind, including
                                                    but not limited to legal, equitable, or statutory claims. Processes for dispute resolution will apply even
                                                    if you stop using your Account, delete your Account, or stop using the Service. They will also apply to
                                                    disputes that arose before we entered into this agreement.</span></p>
                                    <p class="c3"><span class="c0">Sections 8.1 to 8.6 apply to you if you are a resident of the United States or
                                        Canada. They do not apply to you if you are a resident of any other country.</span></p>
                                    <p class="c3"><span class="c0">8.1. Informal Dispute Resolution</span></p>
                                    <p class="c3"><span class="c0">You must try to informally resolve any dispute directly with NoSurrender for at least
                                        thirty (30) days before you start an arbitration. The informal dispute resolution process starts when you
                                        give NoSurrender written notice of the dispute through community@nosurrender.studio.</span></p>
                                    <p class="c3"><span class="c0">8.2. Arbitration Agreement </span></p>
                                    <p class="c3"><span class="c0">You and NoSurrender agree to resolve any disputes exclusively in final and binding
                                        arbitration as follows:</span></p>
                                    <p class="c3"><span class="c0">Either you or NoSurrender may choose to submit any dispute for resolution exclusively
                                        by final and binding arbitration unless the claim is within the exceptions described below. If you or
                                        NoSurrender brings a claim in court that can be resolved by arbitration under this section, then either
                                        party can ask the court to order the parties to resolve the claim by arbitration. The arbitrator will have
                                        the exclusive authority to decide whether any portion of Section 8 (&ldquo;Dispute Resolution&rdquo;) is
                                        valid or enforceable, or whether it applies to a claim.</span></p>
                                    <p class="c3"><span class="c0">An arbitration proceeding will be held before a neutral arbitrator. This means you
                                        and NoSurrender agree to give up the right to resolve the dispute in a trial before a judge or jury.
                                        Arbitration has different rules than more formal lawsuits. For example, the ability to force the other side
                                        to share information may be more limited than the process called discovery in formal lawsuits. After the
                                        arbitrator decides the outcome, that decision will be final. You or NoSurrender can ask the arbitrator to
                                        put a decision or award and the reasons for it in writing. Either of us can ask a court to confirm or enter
                                        the arbitrator&rsquo;s final decision or award, which will make it the same as a court judgment. You and
                                        NoSurrender will generally not be able to change the outcome of arbitration through courts outside of very
                                        limited circumstances. </span></p>
                                    <p class="c3"><span class="c0">8.3. Arbitration Process</span></p>
                                    <p class="c3"><span class="c19">The arbitration will be run by the American Arbitration Association
                                        (&ldquo;AAA&rdquo;). AAA&rsquo;s rules and procedures will be used for the arbitration, including the
                                        Consumer Arbitration Rules. But if there is a conflict between these Terms of Service and AAA&rsquo;s rules
                                        and procedures, then we will follow these Terms of Service. To review AAA&rsquo;s Rules or to start
                                        arbitration, you can go to </span><span class="c19"><a class="c1"
                                            href="https://www.google.com/url?q=https://adr.org/&amp;sa=D&amp;source=editors&amp;ust=1628164674283000&amp;usg=AOvVaw3ZWqO-yUY-aSgE2MufmtWs">AAA&rsquo;s
                                            website</a></span><span class="c0">. If either of us decide to start arbitration, we agree to provide
                                                the other party with a written Demand for Arbitration as specified in the AAA Rules.</span></p>
                                    <p class="c3"><span class="c0">The fees for arbitration will be determined by AAA&rsquo;s Consumer Arbitration
                                        Rules. If the arbitrator decides that those fees are excessive, NoSurrender will pay the fees. Each side
                                        will pay their own attorneys&rsquo; fees and costs unless the claims allow for to the prevailing party to
                                        recover attorneys&rsquo; fees and costs, in which case the arbitrator may award them under the applicable
                                        law. If either party unsuccessfully challenges the validity of the arbitrator&rsquo;s decision or award
                                        through a subsequent court case, the unsuccessful party shall pay the opposing party&rsquo;s costs and
                                        attorneys&rsquo; fees associated with the challenge.</span></p>
                                    <p class="c3"><span class="c0">The arbitration will take place either in San Francisco, California, USA or in the
                                        county or province where you reside.</span></p>
                                    <p class="c3"><span class="c0">8.4 Exceptions to Agreement to Arbitrate</span></p>
                                    <p class="c3"><span class="c0">You and NoSurrender agree that the arbitration agreement in Section 8.2 will not
                                        apply to the following disputes:</span></p>
                                    <ul class="c11 lst-kix_gqh8zrw3qfhj-0 start">
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Claims about NoSurrender&rsquo;s intellectual property, such as
                                            claims to enforce, protect, or concerning the validity of NoSurrender&rsquo;s copyrights, trademarks,
                                            trade dress, domain names, patents, trade secrets, or other intellectual property rights.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Claims related to piracy or tortious interference.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Claims that are not subject to an arbitration agreement as a
                                            matter of law and are not preempted by federal law that would allow for an agreement to
                                            arbitration.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">Claims in small claims court.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">Any dispute not subject to arbitration under these exceptions shall be resolved by a
                                        court of competent jurisdiction as described in Section 10 (&ldquo;Venue for Disputes Not Subject to
                                        Arbitration&rdquo;).</span></p>
                                    <p class="c3"><span class="c0">8.5 No Class Actions</span></p>
                                    <p class="c3"><span class="c0">You and NoSurrender agree that we can only bring claims against each other on an
                                        individual basis.</span></p>
                                    <p class="c3"><span class="c0">That means:</span></p>
                                    <ul class="c11 lst-kix_97rppyfvywnq-0 start">
                                        <li class="c12 c15 li-bullet-0"><span class="c0">You cannot bring a claim against NoSurrender as a plaintiff or
                                            class member in a class, collective, consolidated, or representative action.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">The arbitrator cannot combine any other person&rsquo;s claims
                                            with yours into a single case or preside over any class, collective, consolidated, or representative
                                            arbitration proceeding.</span></li>
                                        <li class="c12 c15 li-bullet-0"><span class="c0">The arbitrator&rsquo;s decision or award in your case will not
                                            apply to anyone else and cannot be used to decide other people&rsquo;s disputes.</span></li>
                                    </ul>
                                    <p class="c3"><span class="c0">If this section (Section 8.5 &ldquo;No Class Actions&rdquo;) is found to be
                                        unenforceable or invalid, then the entirety of Section 8, including Sections 8.1 to 8.6, shall be
                                        void.</span></p>
                                    <p class="c3"><span class="c0">8.6 Opt-Out of Arbitration Agreement and No Class Actions Provisions</span></p>
                                    <p class="c3"><span class="c0">You can choose to opt out of and not be bound by the Arbitration Agreement and No
                                        Class Action provisions above (Sections 8.1 to 8.5) by sending written notice of your decision to opt out to
                                        community@nosurrender.studio with the subject line &ldquo;ARBITRATION AND CLASS ACTION WAIVER
                                        OPT-OUT.&rdquo; You must send us this notice within thirty (30) days of your first use of the Service or
                                        availability of this opt-out, whichever is later. If you do not send us a notice within that time, you will
                                        be bound to arbitrate disputes in accordance with the terms of these paragraphs. If you opt out of these
                                        arbitration provisions, NoSurrender also will not be bound by them.</span></p>
                                    <p class="c3"><span class="c0">9 Applicable Law &nbsp;</span></p>
                                    <p class="c3"><span class="c19">If you are a resident of the United States or Canada: (1) the United States Federal
                                        Arbitration Act (including its procedural provisions) governs the interpretation and enforcement of the
                                        agreement to arbitrate and no class actions provisions of Section 8, and (2) any dispute arising out of or
                                        related to these Terms of Service, the </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">, or the Service shall be governed in all respects by the laws of the
                                                State of California, USA without regard to conflict of law provisions. </span></p>
                                    <p class="c3"><span class="c19">If you are a resident outside of the United States and Canada, you agree that any
                                        claim or dispute arising out of or related to these Terms of Service, the </span><span class="c19"><a
                                            class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">, or the Service shall be governed by the laws of Turkey without
                                                regard to conflict of law provisions. </span></p>
                                    <p class="c3"><span class="c0">10 Venue for Disputes Not Subject to Arbitration</span></p>
                                    <p class="c3"><span class="c0">If you are a resident of the United States or Canada, you agree that any claim or
                                        dispute you may have against NoSurrender that is not subject to arbitration under Section 8 must be resolved
                                        exclusively by a federal or state court located in San Francisco, California. You and NoSurrender consent to
                                        venue and personal jurisdiction in San Francisco, California for all such claims or disputes.</span></p>
                                    <p class="c3"><span class="c0">If you are a resident outside of the United States and Canada, you agree that any
                                        claim or dispute you may have against NoSurrender must be resolved exclusively by a court located in
                                        &#304;stanbul, Turkey.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">11. Severability</span></p>
                                    <p class="c3"><span class="c19">You and NoSurrender agree that if any portion of these Terms of Service or of the
                                        NoSurrender </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">&nbsp;is found illegal or unenforceable, in whole or in part by any
                                                court of competent jurisdiction, such provision shall, as to such jurisdiction, be ineffective solely to the
                                                extent of such determination of invalidity or unenforceability without affecting the validity or
                                                enforceability thereof in any other manner or jurisdiction and without affecting the remaining provisions of
                                                the terms, which shall continue to be in full force and effect.</span></p>
                                    <p class="c3"><span class="c0">&nbsp;</span></p>
                                    <p class="c3"><span class="c0">12. General Provisions</span></p>
                                    <p class="c3"><span class="c0">12.1. Assignment</span></p>
                                    <p class="c3"><span class="c19">NoSurrender may assign or delegate these Terms of Service and/or the NoSurrender
                                    </span><span class="c19"><a class="c1"
                                        href="https://nosurrender.studio/privacy-policy">Privacy
                                        Policy</a></span><span class="c19">, in whole or in part, to any person or entity at any time with or
                                            without your consent. You may not assign or delegate any rights or obligations under the Terms of Service or
                                        </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">&nbsp;without NoSurrender&#39;s prior written consent, and any
                                                unauthorized assignment and delegation by you is ineffective.</span></p>
                                    <p class="c3"><span class="c0">12.2. Supplemental Policies</span></p>
                                    <p class="c3"><span class="c0">NoSurrender may publish additional policies related to specific services such as
                                        forums, contests or loyalty programs. Your right to use such services is subject to those specific policies
                                        and these Terms of Service.</span></p>
                                    <p class="c3"><span class="c0">12.3. Entire Agreement</span></p>
                                    <p class="c3"><span class="c19">These Terms of Service, any supplemental policies and any documents expressly
                                        incorporated by reference herein (including the NoSurrender </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">), contain the entire understanding of you and NoSurrender, and
                                                supersede all prior understandings of the parties hereto relating to the subject matter hereof, whether
                                                electronic, oral or written, or whether established by custom, practice, policy or precedent, between you
                                                and us with respect to the Service.</span></p>
                                    <p class="c3"><span class="c0">12.4. No Waiver</span></p>
                                    <p class="c3"><span class="c19">The failure of NoSurrender to require or enforce strict performance by you of any
                                        provision of these Terms of Service or the NoSurrender </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">&nbsp;or failure to exercise any right under them shall not be
                                                construed as a waiver or relinquishment of NoSurrender&#39;s right to assert or rely upon any such provision
                                                or right in that or any other instance. </span></p>
                                    <p class="c3"><span class="c19">The express waiver by NoSurrender of any provision, condition, or requirement of
                                        these Terms of Service or the NoSurrender </span><span class="c19"><a class="c1"
                                            href="https://nosurrender.studio/privacy-policy">Privacy
                                            Policy</a></span><span class="c0">&nbsp;shall not constitute a waiver of any future obligation to comply
                                                with such provision, condition or requirement.</span></p>
                                    <p class="c3"><span class="c0">Except as expressly and specifically set forth in this these Terms of Service, no
                                        representations, statements, consents, waivers, or other acts or omissions by NoSurrender shall be deemed a
                                        modification of these Terms of Service nor legally binding, unless documented in physical writing, hand
                                        signed by You and a duly appointed officer of NoSurrender.</span></p>
                                    <p class="c3"><span class="c0">12.5. Notices</span></p>
                                    <p class="c3"><span class="c19">We may notify you via email in our games, on </span><span class="c19"><a class="c1"
                                        href="https://nosurrender.studio">nosurrender.studio,</a></span><span
                                            class="c19">&nbsp;via e-mail or any other communications means to contact information you provide to us. All
                                            notices given by you or required from you under these Terms of Service or the </span><span class="c19"><a
                                                class="c1"
                                                href="https://nosurrender.studio/privacy-policy">NoSurrender
                                                Privacy Policy</a></span><span class="c19">&nbsp;shall be in writing and addressed to: NoSurrender Oy.
                                                    Attn: Legal,</span><span class="c44">&#350;AHKULU MAH SERDAR-I EKREM CAD NO:27 D:5 Beyo&#287;lu
                                                        &#304;stanbul. 34394</span><span class="c0">&nbsp;Turkey, with a copy to
                                                            community@nosurrender.studio.</span></p>
                                    <p class="c3"><span class="c0">12.6. Equitable Remedies</span></p>
                                    <p class="c3"><span class="c0">You acknowledge that the rights granted and obligations made under these Terms of
                                        Service to NoSurrender are of a unique and irreplaceable nature, the loss of which shall irreparably harm
                                        NoSurrender and which cannot be replaced by monetary damages alone so that NoSurrender shall be entitled to
                                        injunctive or other equitable relief (without the obligations of posting any bond or surety or proof of
                                        damages) in the event of any breach or anticipatory breach by you. </span></p>
                                    <p class="c3"><span class="c0">You irrevocably waive all rights to seek injunctive or other equitable relief, or to
                                        enjoin or restrain the operation of the Service or any NoSurrender game, exploitation of any advertising or
                                        other materials issued in connection therewith, or exploitation of the Service or any content or other
                                        material used or displayed through the Service and agree to limit your claims to claims for monetary
                                        damages, limited by Section 7 (if any).</span></p>
                                    <p class="c3"><span class="c0">12.7. Force Majeure</span></p>
                                    <p class="c3"><span class="c0">NoSurrender shall not be liable for any delay or failure to perform resulting from
                                        causes outside the reasonable control of NoSurrender, including without limitation any failure to perform
                                        hereunder due to unforeseen circumstances or cause beyond NoSurrender&#39;s control such as acts of God,
                                        war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes,
                                        or shortages of transportation facilities, fuel, energy, labor or materials.</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default TermsOfService
